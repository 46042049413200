.sidebar {
  position: fixed;
  top: 0;
  margin-right: 0.8em;
  height: 100vh;
  background-color: #414141;
}

.sidebarInfo {
  background-color: #414141;
  width: 200px;
  padding: 0.5em 2em 32px 24px;
}
.burgerIcon {
  cursor: pointer;
  position: absolute;
  left: 24;
  top: 34;
}

.container {
  background-color: #363740;
  width: 255;
  padding-top: 32;
  height: calc(100% - 32px);
}

.containerMobile {
  transition: left 0.5s, right 0.5s;
  position: absolute;
  width: 255;
  height: calc(100% - 32px);
  z-index: 901;
}

.mainContainer {
  height: 100vh;
  min-height: 100vh;
}

.mainContainerMobile {
  position: absolute;
  width: 100vw;
  min-width: 100%;
  top: 0;
  left: 0;
}
.outsideLayer {
  position: absolute;
  width: 100vw;
  min-width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.hide {
  left: -255;
}

.show {
  left: 0;
}

.menuItem {
  text-align: left;
}

.title {
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
}
.header {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  margin: 24px 0 0px 0;
}
.operation {
  color: #ffffff;
}

.smallDetails {
  font-size: 12px;
}

.largeDetails {
  font-size: 24px;
  font-weight: 700;
}

.titleBox {
  padding-bottom: 16px;
}

.titleBoxBot {
  padding-bottom: 32px;
}

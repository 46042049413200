.dates {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px 16px 0px;
  width: 100%;

  &Container{
    display: flex;
    justify-content: space-between;
  }
}

.dateSelection{
  margin-right: 24px;
}

.checkBox{
  width: 12px;
  height: 12px;
  color: #128DFF;
  margin-left: 12px;

  &Checked{
    font-size: 17.5px;
    color: #128DFF;
    background-color: #FFFFFF;
  }
  &Unchecked{
    font-size: 17.5px;
    color: #128DFF;
    background-color: #FFFFFF;
  }
}
.toggleEndDate{
  display: flex;
  margin: 12px 12px 12px 0px;
  align-items: center;
  width: 150px;
}

.details{
  margin: 24px 0 12px ;
  &Box{
    padding: 0.7em;
    resize: none;
    width: 735px;
    height: 208px;
    border: 1px solid #dedede;
    border-radius: 3px;
    font-size: 17px;
    margin-top: 12px;
  }
}

.fields{
  &Errors {
    &Text{
      color: red;
      margin: 4px;
    }
    &Container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &Component{
    &Header{
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.confirm{
  &Title {
    font-size: 36px;
    color: red;
    width: 100%;
    text-align: center;
  }
  &Text {
    font-size: 24px;
    margin-bottom: 24px;
    width: 100%;
  }
}

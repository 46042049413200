.navHeader {
  margin-left: 32px;
}

.nav{
  cursor: pointer;
  font-size: 14px;
  margin-right: 0px;
  font-weight: bold;
}

.booking-sandwich {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__button {
    align-self: center;
  }
}


.confirmBooking {
  &__title {
    font-size: 36px;
    color: red;
    width: 100%;
    text-align: center;
  }
  &__text {
    font-size: 24px;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
  }
}

.triangle {
  position: absolute;
  top: 258px;
  left: 632px;
  width: 0;
  height: 0;
  border-right: 0.5em solid transparent;
  border-top: 1px solid #f2f2f2;
  border-left: 0.5em solid transparent;
  z-index: 1;
  box-shadow: 0px 0px 2px 2px #00000029;
}

.service {
  &Status {
    font-size: 16px;
    color: #414141;
    margin-left: 6px;
    margin-top: 3px;
  }
  &Options {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    cursor: pointer;
  }

  &OptionsBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 140px;
    height: 133.49px;
    padding: 7px 0 7px 0px;
    position: relative;
    top: -136px;
    left: -100px;
    background-color: #ffffff;
    border: 0.5px solid #dedede;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #00000029;
  }
}

.black {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #414141;
  &__border {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #414141;
    background-color: none;
  }
}

.red {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ff0000;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.deleteButton {
  margin-top: 24px;
}

.circle{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.green{
  background-color: #3DC700;
}

.orange{
  background-color: #FF8900;
}

.red{
  background-color: #FF0000;
}

.grey{
  background-color: #414141
}

.obdStatus{
  font-weight: bold;
  &Container{
    display: flex;
    align-items: center;
  }
}
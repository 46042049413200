@import "../../constants/breakpoints.scss";
@import "../../constants/variables.scss";

.booking-container {
	position: absolute;
	top: 98px;
	left: 24px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: white;
	border-radius: 25px;
	text-align: center;
	width: 314px;
	padding: 24px 12px;
	box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
}

.booking-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.large-pic {
	width: 90px;
	height: 90px;
}

.medium-pic {
	width: 65px;
	height: 65px;
}

.small-pic {
	width: 46px;
	height: 46px;
}

@media (max-width: 1024px) {
	/*.booking-container {
		display: flex;
		justify-content: space-around;
		align-items: center;
		background-color: white;
		border-radius: 25px;
		text-align: center;
		width: 290px;
		padding: 24px;
		min-width: 12.5em;
		font-size: 70%;
	}*/
}

.booking-image {
	width: 100%;
	height: 100%;
	max-width: none;
	object-fit: cover;
	background-color: #f1f1f1;
}

.booking-info {
	display: flex;

	color: black;
	font-size: 85%;
	margin: 0em 0.5em 0 0.5em;
	text-decoration: none;
	text-align: left;
	&__small {
		color: #414141;
		font-size: 12px;
		margin: 0em 0.5em 0 0.5em;
		text-decoration: none;
		opacity: 0.5;
		text-align: left;
	}
	&__smallNumber {
		color: #414141;
		font-size: 12px;
		margin: 12px 0 0 0;
		text-decoration: none;
		opacity: 0.5;
		text-align: center;
	}

	&__name {
		color: #414141;
		font-size: 12px;
		margin: 0em 0em 0 0.5em;
		text-decoration: none;
		text-align: left;
	}

	&__time {
		color: #414141;
		font-size: 16px;
		margin: 0em 0em 0 0.5em;
		font-weight: bold;
		text-decoration: none;
		text-align: left;
	}
	&__dates {
		color: #414141;
		font-size: 12px;
		margin: 0;
		font-weight: bold;
		text-decoration: none;
		text-align: left;
	}
	&__dates-large {
		color: #414141;
		font-size: 14px;
		margin: 0;
		font-weight: bold;
		text-decoration: none;
		text-align: left;
	}
}

.booking-table-container {
	margin: 0 32px;
}

.booking-row {
	display: flex;
	justify-content: left;
	text-align: left;
	color: #414141;
	font-size: 16px;
	margin: 0 0 0 0;
	text-decoration: none;
}

.booking-row-time {
	display: flex;
	justify-content: left;
	text-align: center;
	color: #414141;
	font-size: 16px;
	margin: 0em 0 0 0;
	text-decoration: none;
}

.booking-time-inner {
	display: inline-block;
	width: 100%;
	text-align: left;
	margin-left: 0.5em;
	margin-right: 0.5em;
}
.booking-time-align {
	width: fit-content;

	margin: auto;
}

.booking-display-map {
	display: flex;
	align-items: center;
}

.booking-mobile {
	width: max-content;
	margin-left: 0.5em;
	text-align: center;
}

.booking-info:visited {
	color: black;
}

.booking-left {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.no-margin {
	margin-top: 0em;
}

.view-profile-link {
	color: $blue;
	margin-top: 0.6em;
}

.no-decoration {
	text-decoration: none;
}

.view-profile-link:visited {
	color: $blue;
}

.late-vehicle {
	animation: pulse 1.8s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0px 0px 0 0px rgba(251, 114, 114, 0.6);
	}
	50% {
		box-shadow: 0px 0px 0 0px rgba(251, 114, 114, 0.5);
	}
	100% {
		box-shadow: 0px 0px 0 8px rgba(251, 114, 114, 0);
	}
}

.booking-sandwich {
	margin-top: 1em;
	display: flex;
	justify-content: space-between;
}

.detail-card-container {
	width: 27%;
	max-width: 13.5em;
}

.detail-card {
	padding: 1.5em 1.2em 1.5em 1.2em;
	border-radius: 3px;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1);
	box-shadow: 0px 0px 10px 0px rgba(211, 211, 211, 1);
}

.detail-card-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1024px) {
	/*.booking-row {
		display: flex;
		justify-content: space-evenly;
		text-align: left;
		color: #414141;
		font-size: 12px;
		margin: 0.5em 0 0 0em;
		text-decoration: none;
	}
	.booking-row-time {
		display: flex;
		justify-content: space-around;
		text-align: left;
		color: #414141;
		font-size: 12px;
		margin: 0.5em 0 0 0;
		text-decoration: none;
	}*/
}

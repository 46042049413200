.svg-inline--fa.fa-fw {
  width: 2em;
  font-size: 2em;
}

.vehicle-top-view-image {
  transform: rotate(-90deg);
  height: 200px;
}

.inspection-description {
  width: 100%;
  height: 20em;
  border: solid 2px;
  border-radius: 5px;
}

.inspection-error {
  color: red;
  text-align: center;
  padding-top: 1em;
}
.gas-slider {
  display: flex;
  flex-direction: row;
  padding: 0 1em;
}

.gas-filled-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gas-filled-box {
}

.gas-percentage {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  width: 3em;
  justify-content: center;
}

.back-to-vehicle {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 7%;
  min-width: 8em;
  margin-left: 3.5em;
}

.service-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.service-button-container button {
  width: 210px;
}

.service-top {
  margin-top: 1em;
  margin-left: 196px;
  z-index: 0;
  width: calc(100vw - 256px);
  height: calc(100vh - 52px);
  overflow-y: auto;
}

@media only screen and (max-width: 900px) {
  .service-top {
    margin-top: 1em;
    margin-left: 196px;
    z-index: 0;
    width: -webkit-fill-available;
    min-height: 450px;
    overflow: auto;
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  .service-top {
    margin-top: 1em;
    margin-left: 196px;
    z-index: 0;
    width: -webkit-fill-available;
    height: max-content;
    overflow: auto;
  }
}

.header-margin {
  margin-left: 3.5em;
  margin-bottom: 2em;
}

.tight-margin {
  margin: 0;
}

.table-top {
  border-top: none;
}

.above-table {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.top-right {
  margin-right: 5%;
}

.tabs {
  display: flex;
  margin-left: 3.5em;
}

.tab-option {
  margin-right: 2em;
  cursor: pointer;
  padding: 0 0.5em 0.3em 0.5em;
}

.selected {
  border-bottom: 4px solid #2190fb;
  transition-duration: 0.2s;
}

.scheduled-tab {
  margin-left: 1em;
}

.unselected {
  color: #9999;
  transition-duration: 0.2s;
}

.modal-overlay {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.visible {
  display: flex;
}

.invisible {
  display: none;
}

.modal {
  position: relative;
  background-color: white;
  animation-name: animatetop;
  animation-duration: 0.3s;
  border-radius: 3px;
  padding: 2em;
  -webkit-box-shadow: 0px 0px 1px 0px #999;
  -moz-box-shadow: 0px 0px 1px 0px #999;
  box-shadow: 0px 0px 1px 0px #999;
  display: flex;
  flex-direction: column;
}

.modal-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-modal {
  width: 50em;
}

.inspection-modal {
  margin-top: 2em;
  height: 80vh;
  width: 35em;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .inspection-modal {
    margin-left: 60px;
    padding-top: 0;
    height: calc(100% - 4em);
    width: calc(100% - 124px);
  }
}

.inspection-input {
  overflow: scroll;
}

.text-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.true-icon {
  color: green;
}

.false-icon {
  color: #fb7272;
}

/*.view-buttons {
  width: 15em;
  text-align: center;
  font-size: 250%;
}*/

.service-action-buttons {
  cursor: pointer;
  font-size: 110%;
  margin-top: 1em;
  width: 30%;
}

.no-link {
  margin: 5px;
}

.date-sel-container {
  display: flex;
  flex-direction: column;
}

.scheduling-tabs {
  display: flex;
  align-items: flex-end;
}

.description {
  height: 11.5em;
  width: 95%;
  font-size: 100%;
  padding: 0.5em;
}

textarea::-webkit-input-placeholder {
  color: #b5b5b5;
  font-weight: 100;
  font-size: 90%;
}

.flex {
  display: flex;
}

.center-align {
  align-items: center;
}

.service-datetime {
  width: 25em;
}

.service-label {
  /* margin: 0 3em 0 0.5em;
  &__time {
    margin: 0 3em 0 0.5em;
    font-size: 12px;
  }*/

  margin: 0 1em 0 0.5em;
}

.history-translate {
  /*transform: translateX(-1em);*/
}

.upcoming-translate {
  /*transform: translateX(-1.8em);*/
}

.inspection-completed {
  margin: 1em 0 0 1.4em;
  font-size: 85%;
  color: grey;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .tab-option {
    margin-right: 2em;
    cursor: pointer;
    padding: 0 10px 0.3em 0em;
  }
}

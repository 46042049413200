.mainButton{
  background: white;
  font-size: 14px;
  border-radius: 50px;
  font-weight: 500;
  margin: 0px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s ease 0s;
  width: 140px;
  height: 34px;
  border: 1px solid #414141;
  color: #414141;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
}

.buttonContainer{
  display: flex;
}
.taskLoading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.taskPage {
  padding: 20px 64px 20px 32px;
  background-color: #f2f2f2;
  color: #414141;
  min-width: 768px;
}

.mainTask {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.taskTitle {
  font-size: 36px;
  font-weight: 600;
  margin: 12px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.taskStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
}

.taskTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 1px #f2f2f2 solid;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}

.leftContainer {
  width: 100%;
  background-color: #ffffff;
  padding: 32px;
  margin-right: 12px;
}

.statusAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.assignContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  justify-content: space-between;
}

.assignAgent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 52px;
  width: 195px;
  font-size: 12px;
}

.subjectInput {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 52px;
  margin-top: 4px;
}

.subjectInputStyle {
  height: 32px;
  padding: 6px 8px;
  font-size: 16px;
}

.assignedAgent {
  font-size: 18px;
}

.changeAgentButton {
  font-size: 10px;
  color: #128dff;
  cursor: pointer;
}

.relatedUserContainer {
  width: 480px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.relateUserTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.relateUserButtons {
  position: relative;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.searchAddContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.chooseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #128dff;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.chooseButtonInvalid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #87cefa;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.chatContainer {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  height: 914px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  background-color: #f2f2f2;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 16px;
}

.dateLine {
  display: flex;
  flex-direction: row;
  > span {
    width: 300px;
    text-align: center;
  }
}

.line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #414141;
  margin: 10px 10px 30px 10px;
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  > span {
    width: 300px;
    text-align: center;
  }
}

.messageContent {
  display: flex;
  flex-direction: row;
  // margin-left: 54px;
  margin-top: 8px;
  align-items: center;
  justify-content: space-between;
}

.messageTagContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.messageTagContainerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.messageTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  padding: 8px 10px;
  border-radius: 12px;
  border: 1px #dedede solid;
  background-color: #dedede;
  border-radius: 16px;
  word-break: break-word;
  max-width: 900px;
  width: fit-content;
}

.messageTagOutgoing {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  padding: 8px 10px;
  border-radius: 12px;
  border: 1px #ffffff solid;
  background-color: #ffffff;
  border-radius: 16px;
  word-break: break-word;
  width: fit-content;
  max-width: 900px;
  align-self: flex-end;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 6px;
  padding: 16px;
  position: relative;
  margin-top: 32px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.inputBox {
  height: 160px;
  margin-bottom: 10px;
  padding: 16px;
  resize: none;
  width: auto;
  border: none;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  font-size: 16px;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
}

.sendMessageButton {
  height: 32px;
  width: 81px;
  background-color: #128dff;
  color: #ffffff;
  border-radius: 20px;
  border: 1px solid #128dff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.sendMessageInvalid {
  height: 32px;
  width: 81px;
  background-color: #87cefa;
  color: #ffffff;
  border-radius: 20px;
  border: 1px solid #87cefa;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.imageUpload > input {
  display: none;
}

.rightContainer {
  width: 670px;
  display: flex;
  flex-direction: column;
}

.dueDateContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 145px;
  margin-bottom: 12px;
  padding: 32px;
}

.booking-info-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 210px;
  padding: 32px;
  margin-bottom: 12px;
}

.dueDateHeader {
  display: flex;
  flex-direction: row;
  > h2 {
    margin: 0;
    font-weight: 500;
  }
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 400px;
  border: 1px solid #414141;
  border-radius: 14px;
  padding: 0 8px 0 16px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
}

.dateInputPop {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  border: 1px solid #414141;
  border-radius: 14px;
  padding: 0 8px 0 16px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
  width: 410px;
}

.datepickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.dueDateCalendar {
  position: absolute;
  right: 165px;
}

.dueDateCalendarPop {
  position: relative;
  right: 32px;
}

.vehicleInfoContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-height: 418px;
  margin-bottom: 12px;
}

.vehicleInfoHeader {
  display: flex;
  flex-direction: row;
  > h2 {
    margin: 0;
    font-weight: 500;
  }
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.chooseAVehicle {
  color: #128dff;
  cursor: pointer;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.vehicleDetailsField {
  width: 50%;
  margin-bottom: 16px;
  min-height: 40px;
}

.subscript {
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 400;
}

.detailField {
  font-weight: 700;
}

.noVehicle {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.5;
}

.vehicleImageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
}

.vehicleImage {
  width: 100%;
}

.relatedVehicleTable {
  width: 1000px;
  height: 800px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.relateVehicleTitle {
  font-size: 32px;
  font-weight: 700;
  margin: 32px 0 0 32px;
}

.relateVehicleButtons {
  position: relative;
  bottom: 32px;
  padding: 0 32px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.vehicleCancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.vehicleChooseButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #128dff;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.vehicleChooseButtonInvalid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #87cefa;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.changeStatusButton {
  position: absolute;
  right: 112px;
  top: 57px;
}

.changeStatusButtonEditMode {
  position: absolute;
  right: 64px;
  top: 57px;
}

.actionButtonsContainer {
  position: absolute;
  right: 64px;
  top: 57px;
}

.multiActionButton {
  width: 155px;
  height: 36px;
  color: #ffffff;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #dedede;
  cursor: pointer;
}

.editModeCancelButton {
  width: 155px;
  height: 36px;
  color: #414141;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  margin-left: 16px;
}

.removeFromBacklogActionButton {
  width: 195px;
  height: 36px;
  color: #ffffff;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #dedede;
  cursor: pointer;
}

.popupContainer {
  width: 480px;
  height: 300px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.popupTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.reasonButtons {
  margin-top: 54px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.cancelContainer {
  width: 416px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.operationSymbol {
  flex-grow: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 0.4em;
  vertical-align: text-top;
}

.statusDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vehicleAbsoluteContainer {
  position: absolute;
  right: 110px;
  margin-top: 12px;
}

.hidden {
  display: none;
}

.menu {
  width: 124px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  background: #ffffff;
  /* Autzu Gray 2 */

  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.menuUp {
  width: 370px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 16px 8px;
  background: #ffffff;
  /* Autzu Gray 2 */

  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  bottom: 8px;
  z-index: 10;
}

.menuOption {
  padding: 8px 0;
  cursor: pointer;
  width: 110px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.actionButton {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tagContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  padding: 32px;
  margin-bottom: 12px;
}

.tagHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  > h2 {
    margin: 0;
    font-weight: 500;
  }
}

.searchAddContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 101px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #128dff;
  color: #128dff;
  cursor: pointer;
  margin-top: 7px;
}

.invalidAddButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 101px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #87caef;
  color: #87caef;
  cursor: pointer;
  margin-top: 7px;
}

.addTagButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #128dff;
  justify-content: space-between;
  width: 100%;
}

.invalidAddTagButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #87caef;
  justify-content: space-between;
  width: 100%;
}

.plusIcon {
  margin-right: 7px;
  font-size: 21px;
}

.plusIconNew {
  margin-right: 7px;
  font-size: 21px;
}

.taskTagsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
}

.relatedTagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 4px 12px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 1px #f2f2f2 solid;
  background-color: #f2f2f2;
  font-weight: 600;
  margin-right: 12px;
  margin-bottom: 8px;
}

.tagTimes {
  margin-left: 12px;
  cursor: pointer;
}

.templateContainer {
  width: 934px;
  height: 660px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.templateTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.searchAndAdd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.templateInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #414141;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 5px 16px;
  width: 250px;
}

.nameInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #414141;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 5px 8px;
  width: 400px;
}

.templateBox {
  background-color: #ffffff;
  width: 888px;
  height: 424px;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: scroll;
}

.templateTextBox {
  background-color: #ffffff;
  border: 1px solid #414141;
  height: 376px;
  padding: 8px;
  border-radius: 8px;
  resize: none;
  overflow-y: auto;
}

.templateButtons {
  position: relative;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 64px;
}

.nameInputStyle {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  background: none;
  color: #414141;
}

.singleTemplateContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.templatePreview {
  width: 168px;
  height: 168px;
  border: 0.5px solid #414141;
  border-radius: 8px;
  padding: 16px;
  overflow-y: hidden;
  margin-bottom: 8px;
  cursor: pointer;
}

.templateTextContent {
  position: relative;
  max-height: 180px;
  overflow: hidden;
}

.shadow {
  position: absolute;
  top: 140px;
  width: 100%;
  height: 3em;
  background: -webkit-linear-gradient(transparent, white);
  background: -o-linear-gradient(transparent, white);
  background: -moz-linear-gradient(transparent, white);
  background: linear-gradient(transparent, white);
}

.discardModal {
  display: flex;
  padding: 32px;
  width: 460px;
  height: 250px;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 16px;
}

.editDeleteButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  width: 170px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.inputStyle {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  background: none;
  margin-left: 12px;
  color: #414141;
}

.imageMsg {
  cursor: pointer;
  max-width: 120px;
  max-height: 120px;
  border-radius: 8px;
}

.bySender {
  font-size: 10px;
  margin-top: 4px;
}

.reminderContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 260px;
  padding: 32px;
}

.reminderHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  > h2 {
    margin: 0;
    font-weight: 500;
  }
}

.datepickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.taskDateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  max-width: 100%;
  width: 124px;
  margin: 8px auto 0;
  border: 1px solid #414141;
  border-radius: 12px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 16px;
  height: 24px;
}

.reminderCalendar {
  position: absolute;
  right: 8%;
}

.accidentReportMessage {
  width: 190px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dedede;
  border-radius: 16px;
  padding: 12px 20px;
}

.damageReportMessage {
  width: 240px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dedede;
  border-radius: 16px;
  padding: 12px 20px;
}

.viewDamageReportButton {
  width: 200px;
  height: 44px;
  color: #ffffff;
  background-color: #128dff;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #dedede;
  cursor: pointer;
}

.acTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 10px;
  align-items: center;
  width: 100%;
}

.acInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 0.25px solid #414141;
  width: 100%;
}

.ticketUrl {
  color: #128dff;
  text-decoration: none;
}

.viewReportButton {
  width: 166px;
  height: 44px;
  color: #ffffff;
  background-color: #128dff;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #dedede;
  cursor: pointer;
}

.fullImageContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
}

.fullDisplay {
  max-width: 600px;
  max-height: 700px;
}

.accidentReportContainer {
  width: 640px;
  height: 720px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.mainTitle {
  font-size: 32px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.accidentDetailBlock {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #414141;
  margin-bottom: 24px;
}

.subTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.accidentDetailsField {
  margin-bottom: 16px;
  min-height: 40px;
}

.damageImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 124px;
}

.accidentImageContainer {
  width: 100px;
  height: 100px;
  background-color: #929292;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.angleImageContainer {
  display: flex;
  flex-direction: column;
}

.closeReport {
  border-color: #414141;
  background-color: #414141;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  margin-left: 550px;
}

.headerText {
  font-size: 18px;
  width: max-content;
}

.templateIcon {
  margin-left: 12px;
  cursor: pointer;
}

.blue {
  color: #128dff;
}

.addReminderUser {
  font-size: 20px;
  margin-bottom: 16px;
}

.popupContainer {
  width: 480px;
  height: 320px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.reasonBox {
  resize: none;
  height: 100px;
  margin-top: 10px;
  padding: 6px 8px;
  border-radius: 5px;
}

.reasonInput {
  display: flex;
  flex-direction: column;
}

.reasonButtons {
  margin-top: 32px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.taskPopupTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.noButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.yesButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #128dff;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.yesButtonInvalid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #87cefa;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.editModeButtons {
  display: flex;
  flex-direction: row;
}

.cancelInfoEdit {
  display: flex;
  margin-right: 15px;
}

.vehicleInfoInput {
  width: 124px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #dedede;
  font-size: 15px;
}

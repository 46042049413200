.absoluteContainer {
  position: absolute;
  right: 0;
  top: 48px;
}

.hidden {
  display: none;
}

.menu {
  width: 124px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  background: #ffffff;
  /* Autzu Gray 2 */

  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.menuUp {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px 12px;
  background: #ffffff;
  /* Autzu Gray 2 */

  border: 1px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
  bottom: 8px;
  z-index: 10;
}

.menuOption {
  padding: 3px 0;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notesHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

svg.cross.icon {
  height: 25px;
  width: 25px;
}

.title {
  font-size: 36px;
}

.notesTitle {
  color: #414141;
  font-size: 28px;
  font-weight: bold;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notesContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
}

/*.docContainer {
	display: flex;
	flex-direction: column;
	width: 800px;
	height: 400px;
	background-color: #f2f2f2;
}


.docHeader {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 32px 0 2em 0;
}

.docTitle {
	color: #414141;
	font-size: 31px;
	font-weight: bold;
}*/

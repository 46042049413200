.container {
	display: flex;
	flex-direction: column;
}

.text {
	text-align: center;
	color: #414141;
}

.titleText {
	text-align: left;
	font-weight: bold;
	font-size: 20px;
	color: #414141;
}

.marginBottom {
	margin-bottom: 2em;
}

.buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.currentDTC {
  width: 798px;
  height: 399px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px 42px;
  display: flex;
  flex-direction: column;
}

.titles {
  display: flex;
  flex-direction: row;
  margin-bottom: 11px;
  font-size: 16px;
}

.titleDTC {
  padding-left: 23px;
  width: 144px;
  font-weight: 700;
}

.titleDescription {
  padding-right: 268px;
  font-weight: 700;
}

.titleStartTime {
  padding-right: 157px;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.recordBox {
  width: 713px;
  height: 246px;
  background-color: #ffffff;
  overflow-y: auto;
  border-radius: 5px;
  padding: 12px;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.curDTCCloseButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.rowDTCInfo {
  padding: 12px 18px 12px 12px;
  height: 60px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
}

.titleDTCInner {
  width: 120px;
  pointer-events: none;
}

.titleDescriptionInner {
  width: 330px;
  pointer-events: none;
  display: block;
  overflow-wrap: break-word;
}

.titleEndInner {
  pointer-events: none;
}

.pastDTC {
  width: 1038px;
  height: 399px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px 44px;
  display: flex;
  flex-direction: column;
}

.recordBoxLarge {
  width: 950px;
  height: 246px;
  background-color: #ffffff;
  overflow-y: auto;
  border-radius: 5px;
  padding: 12px;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.titleStartInner {
  padding-left: 20px;
  width: 230px;
  pointer-events: none;
}

.titleEndInner {
  padding-left: 20px;
}

.reviewChanges{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 800px;
  height: 500px;
  background: #F2F2F2;
  border-radius: 16px;
  padding: 32px;
}

.footer{
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.title{
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;

  /* Autzu Gray 1 */

  color: #414141;
}

.count{
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: right;

  /* Autzu Gray 1 */

  color: #414141;
}
.large-pic {
  width: 90px;
  height: 90px;
}

.medium-pic {
  width: 65px;
  height: 65px;
}

.small-pic {
  width: 46px;
  height: 46px;
}

.booking-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-image {
  height: 100%;
  max-width: none;
  background-color: #f1f1f1;
}

.circle{
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.available{
  border: 2px solid #128DFF;
}

.user-booking{

}

.service-booking{

}

.late-booking{
  animation: pulse 1s infinite
}

.unauthorized{
  animation: pulse 1s infinite
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 3px 3px #FF0000;
  }
  100% {
    box-shadow: 0 0 1px 1px #FF0000;
  }
}
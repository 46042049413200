.mode-select-bar {
}

.tab-container {
  border-width: 1px;
  height: 100%;
}

.time-box {
  justify-content: space-between;
  width: auto;
  margin-right: 1.5em;
}

.custom-time-select {
  color: #414141;
}

.time-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px 0;
}

.react-datepicker__month-container {
  border-width: 0.5px;
}

.react-datepicker__today-button {
  border-top: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  height: 30px;
  border-width: 1px;
  padding: auto;
}

.react-datepicker__today-button:hover {
  background-color: #9999;
}

.react-datepicker__time-list {
  padding: 0;
}

.button{
  height: 34px;
}

.buttonGroup{
  margin-right: 28px;
  display: flex;
}

.marginRight{
  margin-right: 12px;
}
.damagePage {
  padding: 0 32px;
  background-color: #f2f2f2;
  color: #414141;
  margin-left: 200px;
}

.landscape {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.damageTopContainer {
  display: flex;
  min-width: 780px;
  padding: 16px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  background-color: #fff;
  margin-bottom: 16px;
  flex-direction: column;
}

.damageIndexContainer {
  margin-left: 200px;
}

.topTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bold {
  font-weight: 700;
}

.addButton {
  display: flex;
  color: #128dff;
  font-weight: 700;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #128dff;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.damageBasicAndDiagram {
  display: flex;
  flex-direction: row;
}

.damageOverview {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
}

.damageTopLeft {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.overviewData {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.subTitle {
  font-size: 12px;
  margin-bottom: 4px;
}

.damageDiagram {
  display: flex;
  flex-direction: row;
}

.diagramAnnotation {
  display: flex;
  flex-direction: column;
}

.marginRight {
  margin-right: 8px;
}

.annotationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: space-between;
  margin-bottom: 16px;
}

.diagram {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 100px;
}

.diagramLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  justify-content: space-between;
}

.diagramMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.diagramRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  justify-content: space-between;
}

.teslaDiagram {
  height: 318px;
}

.locationBox {
  display: flex;
  width: 164px;
  padding: 12px;
  flex-direction: column;
  align-items: space-between;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background-color: #fff;
  cursor: pointer;
}

.locationBoxHover {
  display: flex;
  width: 164px;
  padding: 12px;
  flex-direction: column;
  align-items: space-between;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #dedede;
  cursor: pointer;
  background-color: #dedede;
}

.locationDamages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.damageCircleUnconfirmed {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: #f2f2f2;
}

.textUnconfirmed {
  font-size: 12px;
  font-weight: 700;
  color: #414141;
}

.damageCircleMinor {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: #b6dcff;
}

.textMinor {
  font-size: 12px;
  font-weight: 700;
  color: #128dff;
}

.damageCircleMedium {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: #ffd8ab;
}

.textMedium {
  font-size: 12px;
  font-weight: 700;
  color: #ff8900;
}

.damageCircleSevere {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background-color: #ffb5b5;
}

.textSevere {
  font-size: 12px;
  font-weight: 700;
  color: #f00;
}

.carAndLines {
  position: relative;
}

.verticalLine {
  position: absolute;
  top: -170px;
  right: 248px;
  z-index: 1;
}

.horizontalLine {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.clickable {
  cursor: pointer;
  color: #128dff;
  margin-left: 6px;
}

.galleryContainer {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  // width: 1216px;
  // height: 705px;
  align-self: stretch;
  border-radius: 16px;
  background: #f2f2f2;
}

.galleryLeft {
  display: flex;
  flex-direction: column;
  position: relative;
}

.galleryRight {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 32px;
  height: 100%;
  justify-content: space-between;
}

.rightUpper {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  width: 896px;
  height: 705px;
  display: flex;
  object-fit: contain;
}

.imageContainerNoImage {
  width: 896px;
  height: 705px;
  display: flex;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.arrowButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 180px;
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.footerText {
  width: 180px;
  color: #ffffff;
  text-align: right;
}

.imageFooter {
  display: flex;
  flex-direction: row;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #414141;
  padding: 12px 0;
}

.infoTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.timesIcon {
  font-size: 24px;
  cursor: pointer;
}

.infoData {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: none;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  font-size: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-weight: 700;
}

.reportButton {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: #414141;
}

.damageModalContainer {
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: 16px 32px;
  border-radius: 16px;
  background: #f2f2f2;
}

.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.subscript {
  font-size: 12px;
}

.description {
  resize: none;
  border-radius: 8px;
  border: 1px solid #414141;
  background-color: #fff;
  margin-top: 6px;
  width: 100%;
  height: 66px;
  padding: 8px;
}

.tagInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
}

.tagInput {
  display: flex;
  height: 32px;
  padding: 6px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #414141;
  background: #fff;
  width: 100%;
  margin-right: 8px
}

.tagsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.addTagButton {
  display: flex;
  color: #128dff;
  font-weight: 700;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: #fff;
  cursor: pointer;
}

.tempTagContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 4px 12px;
  border-radius: 12px;
  border: 1px #f2f2f2 solid;
  background-color: #dedede;
  font-weight: 600;
  margin-bottom: 8px;
}

.tagTimes {
  margin-left: 12px;
  cursor: pointer;
}

.imageUploader {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.imageUpload {
  position: relative;
  margin: 0 12px 8px 0;
}

.imageUpload > input {
  display: none;
}

.imageTimesIcon {
  position: absolute;
  top: -10px;
  right: -8px;
  font-size: 24px;
  cursor: pointer;
  color: #f00;
}

.imageField {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 416px;
}

.cancelButton {
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  border: none;
  padding: 6px 16px;
  width: 86px;
  font-size: 16px;
}

.applyButton {
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  color: #128dff;
  border: none;
  padding: 6px 16px;
  width: 86px;
  font-size: 16px;
}

.tabContainer {
  display: flex;
  flex-direction: row;
}

.selected {
  opacity: 1;
  background-color: white;
}

.detailTab {
  // opacity: 0.5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 56px;
  font-weight: 600;
  cursor: pointer;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.detailsPage {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 32px;
  background-color: #fff;
}

.detailBasics {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.editButton {
  display: flex;
  width: 82px;
  padding: 6px 16px;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.indicatorImage {
  width: 87px;
  height: 212px;
}

.detailImages {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.mainImage {
  width: 630px;
  height: 400px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid #dedede;
}

.displayImages {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.displayImage {
  width: 84px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}

.fadedDisplayImage {
  opacity: 0.5;
  width: 84px;
  height: 84px;
  flex-shrink: 0;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}

.changeStatusContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 480px;
  padding: 32px;
  border-radius: 16px;
  background: #f2f2f2;
  gap: 16px;
}

.textInput {
  width: 416px;
  height: 32px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #414141;
}

.taskTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.taskTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
}

.link {
  text-decoration: none;
  cursor: pointer;
  color: #414141;
}

.addTaskButtonContainer {
  position: absolute;
  bottom: 32px;
  right: 32px;
}

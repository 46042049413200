.navPath {
	display: flex;
	align-items: center;
	justify-content: left;
	max-width: calc(100% - 60px);
	width: 1575px;

	padding: 20px 0 0px 0;
}

.navPathTitle {
	cursor: pointer;
	font-size: 14px;
	margin-left: 12px;
	&__selected {
		cursor: pointer;
		font-size: 14px;
		margin-left: 12px;
		font-weight: bold;
	}
}

.navTitleInitial {
	cursor: pointer;
	font-size: 14px;
	margin-right: 0px;
	&__selected {
		cursor: pointer;
		font-size: 14px;
		margin-right: 0px;
		font-weight: bold;
	}
}

.pathContainer {
	display: flex;
	margin-left: 12px;
}

.navPathIcon {
	font-size: 14px;
	&__selected {
		font-size: 14px;
		font-weight: bold;
	}
}

@media (max-width: 1024px) {
	.navPath {
		display: flex;
		align-items: center;
		width: 964px;
		margin: 0 32px 0px 32px;
	}
}

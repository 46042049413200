@mixin button{
  height: 39px;
  width: unset;
  font-weight: bold;
  border: none;
  font-size: 17px;
}

.makeAvailableButton {
  @include button;
  color: #FF0000;
}

.cancelButton{
  @include button;
  color: #414141;
}

.buttonContainer{
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.title {
  font-size: 36px;
  color: red;
  width: 100%;
  text-align: center;
}

.text {
  font-size: 24px;
  width: 100%;
  text-align: center;
}
@mixin button{
  background: white;
  border: 2px solid rgb(18, 141, 255);
  color: rgb(18, 141, 255);
  font-size: 14px;
  border-radius: 50px;
  font-weight: 500;
  margin: 0px;
  padding: 0.36em 1em;
  width: 8.5em;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s ease 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  width:16px;
  height:16px;
  border:1px solid #414141;
  font-size: 12px;
  font-weight: bold;
  padding:0;
  border-radius: 50%;
  text-indent: -0.5px;
  &:focus {
    outline: none;
  }
}

.defaultButton{
  @include button;
  color: #414141
}

.selectedButton{
  @include button;
  background-color: #414141;
  color: #FFFFFF
}
.upload__csv {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.upload__csv a {
  margin-bottom: 24px;
}

.upload__csv--container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
}

.upload__csv--data {
  border: solid 1px grey;
  height: 100%;
  display: flex;
}

.upload__csv--plates {
  border-right: solid 1px grey;
  padding: 24px;
  width: 10%;
  overflow: scroll;
}

.upload__csv--review {
  width: 90%;
  display: flex;
}

.upload__csv--vehicle-details {
  border-right: solid 1px grey;
  width: 50%;
  padding: 24px;
}

.upload__csv--service-history {
  width: 50%;
  padding: 24px;
}

.upload__csv--block {
  display: flex;
}

.upload__csv--title {
  width: 50%;
  font-weight: bold;
}

.upload__csv--title {
  width: 50%;
}

.upload__csv--plates--block {
}

.upload__csv--plates--block-selected {
  background: #51a0fb;
}

.upload__csv--buttons {
  width: 100%;
}

.upload__csv--message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload__reponse--container {
  border-collapse: collapse;
  width: 80%;
}

.upload__reponse--success {
  background: green;
}

.upload__reponse--error {
  background: red;
}

.upload__reponse--messages {
  padding: 8px;
  width: 50%;
}

.upload__reponse--plate {
  padding: 8px;
  width: 50%;
}

.upload__csv table,
.upload__csv th,
.upload__csv td {
  border: 1px solid black;
}

.name {
  margin-left: 6em;
}

.other {
  margin-left: 1em;
}

.description {
  margin-top: 3em;
}

.errorContainer{
  padding: 0.7em;
  color: red;
}

.errorContainer{

}

.errors{

}

.modalText{
  margin-bottom: 24px;
}

.modalContent{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.selectField {
  display: flex;
}

.text {
  margin-top: 12px;
}

.padding {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5em;
}

.title {
  composes: padding;
  margin-right: 0.5em;
  white-space: nowrap;
}

.number {
  composes: padding;
}

.mileage {
  display: flex;
}

.section {
  display: flex;
  width: 45%;
}

.input {
  margin-bottom: 0.25em;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.toggleButtonContainer {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleButtonContainerWhiteBack {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 0.5;
}

.darkBackground {
  background-color: #414141;
}

.selected {
  color: #ffffff;
}

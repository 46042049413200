@import "../../../../constants/variables.scss";
.statusCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.expandableList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textBubble {
  overflow-x: hidden;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.extraBubbleCount {
  font-size: 12px;
  width: 18px;
  color: #128dff;
  text-align: right;
}
.bubbleTextContainer {
  display: flex;
  align-items: center;
  border-radius: 14px;
  padding: 10px 14px;
  // border: 1px solid #414141;
  height: 23px;
  background-color: #f2f2f2;
}

.expandableListItem {
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  display: flex;
  align-items: center;
}

.statusValue {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.statusText {
  margin-left: 4px;
}
.textValue {
  overflow-wrap: anywhere;
  text-align: left;
}

.boldText {
  font-weight: 700;
}

.subtext {
  font-size: 12px;
  color: #a0a0a0;
  text-align: center;
}

.imageValue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-width: 111px;
  max-height: 46px;
}

.statusMain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userImage {
  width: 100%;
}

.userImageContainer {
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px $dark-grey;
}

.userNameLink {
  margin-left: 8px;
  color: #128dff;
}

.userName {
  margin-left: 8px;
}

.userContainer {
  display: flex;
  align-items: flex-start;
  width: 130px;
}

.taskTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}

.parkingTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding: 2px 12px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 13px;
  font-weight: 700;
  margin-left: 12px;
}

.licensePlateAutzu {
  overflow-wrap: anywhere;
  text-align: center;
  text-decoration: none;
  color: #128dff;
}

.licensePlatePersonal {
  overflow-wrap: anywhere;
  text-align: center;
  text-decoration: none;
  color: #414141;
}

@import "../../constants/breakpoints.scss";
@import "../../constants/variables.scss";

.dropdown {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  bottom: 0px;
  width: 280px;
  background: white;
  border-radius: 25px;
  text-align: center;
  padding: 24px;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
  left: 65px;
  border: 1px solid #414141;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    bottom: -125px;
    width: 280px;
    background: white;
    border-radius: 25px;
    text-align: center;
    padding: 24px;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
    left: 65px;
  }
}

.city {
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.parkingLot {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.hover:hover {
  cursor: pointer;
  color: $blue;
}

.triangle {
  position: absolute;
  bottom: 32px;
  left: 58px;
  width: 0;
  height: 0;
  border-right: 0.5em solid white;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  z-index: 1;
}

.textContainer {
  text-align: left;
  font-weight: 200;
  &:not(:first-of-type) {
    margin-top: 12px;
  }
}

.dropdownContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.closed {
  display: none;
}

.selected {
  color: $blue;
  font-weight: 600;
}

.reset {
  margin-top: 16px;
  cursor: pointer;
}

.save {
  margin-top: 16px;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.backdrop {
  display: flex;
  position: absolute;
  align-items: flex-start;
  bottom: 0px;
  width: 250px;
  padding: 24px;
  left: 65px;
  // z-index: 2;
}

.displayName {
  margin-right: auto;
}

.dropdownContent {
  width:100%
}
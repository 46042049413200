.dropdownLabel {
  font-size: 16px;
  font-weight: 600;
}

.dropdown {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  height: 100%;
  // width: 124px;
  width: fit-content;
  margin-right: 40px;
}

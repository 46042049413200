@import "../../constants/breakpoints.scss";
@import "../../constants/variables.scss";

.create-booking {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__settings-container {
    display: flex;
    justify-content: left;
    width: 100%;
  }
  &__comments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-top: 32px;
  }
  &__schedule-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-right: auto;
  }
  &__time-container {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 16px;
    margin-right: auto;
  }
  &__check-box {
    display: flex;
    margin: 12px 12px 12px 0px;
    width: 150px;
  }

  &__time-box {
    margin: 12px 12px 12px 0;
  }

  &__button-container {
    width: 50%;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
    bottom: 0;
  }

  &__error {
    color: red;
    margin: 4px;
    &-container {
    }
  }

  &__titles {
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    margin: 32px 0 8px 0;
  }
  &__header {
    font-size: 16px;
    font-weight: bold;
  }
  &__category {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  &__other-category {
    max-width: 60%;
    display: flex;
    justify-content: space-between;
  }
}

.center-vertical {
  align-items: center;
}

#vehicle-page {
  display: flex;
  margin: 52px 3.5em 0 0;
  background-color: #f2f2f2;
  height: calc(100vh - 52px);
  max-width: calc(100vw - 229px);
}

.vehicle-action-button {
  width: 30px;
  margin: 0.2em;
  color: $blue;
}

.vehicle-button-container {
  border-radius: 0px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  flex-grow: 1;
}

.lock-event-button {
  flex-grow: 1;
  height: 4em;
}

// .vehicle-buttons {
//   width: 704px;
//   display: flex;
//   align-items: center;
//   margin-top: 1em;
//   margin-left: 228px;
//   border-radius: 0.3em;
// }
// .vehicle-details-tab {
//   display: flex;
//   width: 704px;
//   min-width: 480px;
//   background-color: #f2f2f2;
// }

.tab-options {
  display: flex;
  cursor: pointer;
  width: 160px;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
  margin-bottom: 0;
  opacity: 0.5;
  color: #414141;
}

.extension {
  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__amount {
    font-size: 60%;
    color: $medium-grey;
  }

  &__icon {
    color: $blue;
    margin-left: 10px;
  }

  &__container {
    display: flex;
  }
}

.vehicle-container {
  display: flex;
  width: 704px;

  &__service {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: solid 1px $grey;
  }

  &__button-container {
    display: flex;
    margin-bottom: 24px;
    a {
      margin-right: 24px;
    }
  }
}
.vehicle-container-details {
  display: flex;
  width: 704px;
  height: 440px;
  padding: 32px;
}

.lockbox-code {
  &__history {
    margin: 10px;
  }
}
.vehicle-information {
  text-align: left;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 12px;
  &__lockbox-code {
    color: $blue;
    display: flex;
  }
  &__lockbox-history {
    margin: 0 12px;
    font-weight: bold;
    font-size: 16px;
    color: $blue;
    text-align: left;
  }
}

.map-link {
  display: flex;
  justify-content: center;
}

.map-link-button {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #128dff;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-container {
  width: 460px;
  max-height: 296px;
  background-color: #ffffff;
  margin-right: 32px;
  padding: 32px 28px 32px 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vehicle-header-footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header {
  align-items: center;
  height: 283px;
  background-color: #f2f2f2;
}
.header-icons {
  margin-top: 20px;
}
.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.odometer-display {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.odometer-icons {
  padding-right: 10px;
  font-size: 16px;
}

.fuel-icons {
  padding-right: 10px;
  font-size: 16px;
}
.fuel-bar {
  margin-right: 9px;
  width: 82px;
  height: 8px;
  border: 1px solid #414141;
  border-radius: 8px;
}
.fuel-fill {
  background-color: #414141;
  border-radius: 8px;
  height: 100%;
}
.operation-container {
  display: flex;
  width: 100%;
  text-align: left;
  margin: 0;
}
.status-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 212px;
  min-height: 287px;
  padding: 24px 32px;
}
.operation-symbol {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 0.4em;
  vertical-align: text-top;
}
.operation-title {
  font-size: 18px;
  color: #414141;
  opacity: 0.5;
}

.operation-status {
  font-size: 16px;
  font-weight: bold;
  color: #414141;
  margin-top: 3px;
}

.operation-status-sidebar {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 3px;
}
.service-button {
  margin-top: auto;
  display: flex;
  margin-bottom: 20px;
}
.vehicle-col {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.vehicle-details {
  margin-left: 197px;
  padding-left: 32px;
  background-color: #f2f2f2;
  width: calc(100vw - 197px - 60px);
  height: calc(100vh - 52px);
  overflow-y: auto;
}

.vehicle-service {
  margin-left: 228px;

  padding-right: 32px;
  height: calc(100vh - 52px);
  overflow-y: auto;
}

@media only screen and (max-width: 900px) {
  .vehicle-details {
    margin-left: 197px;
    padding-left: 32px;
    background-color: #f2f2f2;
    width: max-content;

    min-height: 450px;
    overflow-y: auto;
  }
  .vehicle-service {
    margin-left: 228px;
    width: max-content;
    padding-right: 32px;
    min-height: 450px;
    min-width: max-content;
    overflow-y: auto;
  }
}

@media (max-width: 1024px) {
  .vehicle-service {
    margin-left: 228px;
    width: calc(100% + 32px);
    padding-right: 32px;
    height: calc(100vh - 52px);
    overflow-y: auto;
  }
}

.service-text {
  &__large {
    font-size: 19px;

    text-align: left;
    font-weight: bold;
  }
  &__medium {
    font-size: 15px;

    text-align: left;
    font-weight: bold;
  }
  &__small {
    font-size: 14px;
    margin: 5px 0 5px 0;
    text-align: left;
  }
}

.display-details-photo {
  width: 381px;
  height: auto;
}

.end {
  display: flex;
  justify-content: flex-end;
  text-align: end;
}

.blue {
  color: #03708f;
}

.show-more {
  cursor: pointer;
}

.view-buttons {
  margin-top: 1em;
  font-size: 80%;
}

.small {
  font-size: 75%;
}

.details {
  font-weight: 700;
}

.med-margin {
  margin-top: 1.5em;
}

.tight-margin-details {
  font-size: 12px;
  color: #414141;
  margin: 0;
  text-align: left;
}

.tight-margin-large {
  font-size: 16px;
  color: #414141;
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.info-small-title {
  font-size: 12px;
  color: #414141;
  text-align: left;
}

.info-large-title {
  font-size: 19px;
  color: #414141;
  font-weight: bold;
  margin: 0.4em 0 0.3em 0;
  text-align: left;
}

.edit-tab {
  display: flex;
  cursor: pointer;
  font-size: 18px;
  color: #414141;
  font-weight: bold;
  margin: 0.4em 0 0.3em 0;
  text-align: left;
  width: 200px;
  align-items: center;
  justify-content: flex-end;
}

.table-edit {
  display: flex;
  cursor: pointer;
  font-size: 33px;
  margin: 0.4em 0 0.3em 0;
  text-align: left;
  align-items: center;
}

.edit-tab {
  display: flex;
  cursor: pointer;
  font-size: 18px;
  color: #414141;
  font-weight: bold;
  margin: 0.4em 0 0.3em 0;
  text-align: left;
  width: 200px;
  align-items: center;
  justify-content: flex-end;
  font-size: unset;
}

.right-tab {
  margin: 0.4em 0 0.4em 1.5em;
}

.vehicle-margin {
  margin: 0 0 0.5em 0;
}

.top {
  margin-top: 2em;
}

.large {
  font-weight: 700;
  font-size: 140%;
}

.save-buttons-details {
  display: flex;
  justify-content: space-between;
  min-width: 215px;
  max-width: 215px;
}

.unavailable-info {
  min-height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 16px;
}

.textbox-input {
  padding: 0.7em;
  margin-left: 32px;
  resize: none;
  width: 640px;
  height: 80px;
  border: 1px solid #dedede;
  border-radius: 3px;
  font-size: 17px;
}

.date-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0 32px 0;
}

.edit-textbox-input {
  padding: 0.7em;
  resize: none;
  width: 735px;
  height: 208px;
  border: 1px solid #dedede;
  border-radius: 3px;
  font-size: 17px;
  margin-top: 12px;
}

.editTextboxInput {
  padding: 0.6em;
  resize: none;
  width: 536px;
  height: 112px;
  border: 1px solid #dedede;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 32px;
}

.edit-note-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.note-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 32px 32px 12px;
}
.add-note-title {
  font-size: 16px;
  font-weight: bold;
  margin: 32px 32px 8px 32px;
}
.add-note-header {
}
.add-note {
  justify-content: flex-end;
  font-size: 17px;
}
.edit-note {
  margin-top: 24px;
  justify-content: flex-end;
  font-size: 17px;
}

.cancel-note {
  margin-top: 24px;
  justify-content: flex-start;
  font-size: 17px;
}
.notes-user-image {
  display: flex;
}
.full-width {
  width: 100%;
  margin-bottom: 1em;
}

.notes-box {
  width: auto;
  max-width: 648px;
  padding-bottom: 24px;
  margin: 0 32px;
}

.notes-container {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  margin: 0;
}

.note {
  width: auto;
  min-width: -webkit-fill-available;
}

.note-header {
  display: flex;
  justify-content: space-between;
}

.note-user {
  font-size: 17.5px;
  margin-left: 8px;
  color: #414141;
  font-weight: bold;
}
.note-date-container {
  display: flex;
  justify-content: flex-end;
}
.note-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  opacity: 0.5;
}

.note-details-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 28px;
}
.note-details {
  word-wrap: break-word;
  margin: 12px 0 12px 0;
  color: #414141;
  font-size: 16px;
  // max-width: 252px;
}

.pin-delete-note {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pin-note {
  font-size: 16px;
  cursor: pointer;
  color: #414141;
}
.delete-note {
  font-size: 16px;
  cursor: pointer;
  color: #ff0000;
}

.icon-buttons {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reminder-box {
  width: fit-content;
  height: 34px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;

  border: 1px solid #414141;
  border-radius: 17px;
  padding: 0 0 0 8px;
}

.no-margin {
  margin: 0;
}

.small-font {
  font-size: 85%;
}

.back-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1em;
  min-width: 3.5em;
}

.back-text {
  margin-left: 0.6em;
}
.small-title {
  font-size: 11px;
}

.medium-title {
  font-size: 17.5px;
  font-weight: bold;
  padding: 12px 0 12px 0;
}

.big-title {
  font-size: 31px;
  font-weight: bold;
  padding: 12px 0 12px 0;
}

.link {
  text-decoration: none;
  color: white;
}

.link:visited {
  color: white;
}
.grey {
  background-color: grey;
}

.grey-text {
  color: grey;
}

.view-on-map {
  margin: 0 !important;
  max-width: 25%;
  border-bottom: 5px solid #03708f;
}

.vehicle-redirects {
  position: absolute;
  top: 0;
  left: 200px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin: 0 32px 0 32px;
}

@media (max-width: 1024px) {
  .vehicle-redirects {
    position: absolute;
    top: 0;
    left: 200px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;
  }
}

.check {
  cursor: pointer;
  color: green;
  margin-left: 1em;
}

.odometer-input {
  width: 6em;
  text-align: right;
}

.left-gap {
  margin-left: 1em;
}

.red {
  color: #fb7272;
}

.yellow {
  color: #f5a522;
}

.green {
  color: #63ca9c;
}

.fast-spin {
  -webkit-animation: fa-spin 0.8s infinite linear;
  animation: fa-spin 0.8s infinite linear;
}

.stack-icons {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stacked-icon {
  position: absolute;
  width: 100%;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.doc-details-layout {
  display: flex;
  height: auto;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .doc-details-layout {
    display: flex;
    height: auto;
    justify-content: space-between;
    margin-right: 32px;
  }
  .documents-table {
    width: calc(100vw - 289px);
  }
}

.documents-table {
  margin-left: 32px;
  height: calc(100vh - 64px);
  position: absolute;
  overflow-y: auto;
  left: 197px;
}

@media only screen and (max-width: 900px) {
  .documents-table {
    margin-left: 32px;
    width: max-content;
    position: absolute;
    overflow-y: auto;
    min-height: 450px;
    left: 197px;
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  .vehicle-details {
    margin-left: 197px;
    padding-left: 32px;
    background-color: #f2f2f2;
    width: max-content;
    height: max-content;
    min-height: 500px;
    overflow-y: auto;
  }
  .documents-table {
    margin-left: 32px;
    width: max-content;
    position: absolute;
    overflow-y: auto;
    height: max-content;
    left: 197px;
  }
}

.doc-header {
  font-weight: bold;
  font-size: 17.5px;
}

.doc-body {
  font-size: 17px;
  color: #414141;
}

.doc-container {
  width: 360px;
  margin-bottom: 24px;
}

.doc-title {
  display: flex;
  justify-content: space-between;
  width: 694px;
  margin-bottom: 12px;
}

.document-preview {
  width: 312px;
  height: 409px;
  background-color: #dedede;
  margin-top: 24px;
}

.upload-table {
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 5em;
  margin-left: 228px;
  width: 704px;

  td {
    border-bottom: 1px solid #dedede;
  }
  .upload-image {
    width: 60px;
    height: 60px;
    border-radius: 20%;
  }
}

.upload-image {
  width: 36px;
  height: 48px;
  border-radius: 20%;
}

#upload-modal {
  padding: 0;
  > div {
    padding: 0;
    width: 700px;
  }
}

.upload__modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;

  &__preview {
    width: 112px;
    height: 149px;
    background-color: #ffffff;
    border-radius: 0;
    color: white;
    position: relative;

    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

    &--black {
      filter: none;
      background-color: black;
    }

    &--img {
      position: absolute;
      width: 100%;

      transform: translate(-50%, -50%);
    }

    &__icon {
      text-align: center;
      color: white;
      width: 0;
    }
  }

  &__inputs {
    display: flex;
    padding: 1.5em 0em 0px 0em;
    color: #414141;

    position: relative;

    &--close {
      text-align: end;
    }

    .upload__modal__inputs__title {
      > div {
        padding: 0 0 15px 0px;
      }

      .upload__modal__inputs__title--name {
        font-weight: bold;
        margin: auto;
        padding: 0;
        font-size: 17.5px;
      }

      &--options {
      }

      &--other {
        position: relative;
        top: -15px;
        padding-left: 55px;
      }
    }

    .upload__modal__inputs__expDate {
      > div {
        padding: 0 0 0px 0px;
        margin-top: 32px;
      }
      &--name {
        font-weight: bold;

        white-space: nowrap;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      &--input {
        width: 289px;
        border-radius: 0px;
        height: 24px;
        border: 2px solid #414141;
        color: grey;
        font-size: 17.5px;
        padding: 0.7em;
      }
    }

    .upload__modal__inputs__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      width: 100%;

      > a > button,
      > button {
      }
    }

    &__displayName {
      font-size: 36px;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 20px;
      word-break: break-all;
    }

    &__margin {
      margin-bottom: 15px;
    }

    .upload__modal__inputs--bold {
      font-weight: bold;
    }
  }

  &__loading {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
  }

  &__error {
    color: red;
    font-style: italic;
  }
}

.upload-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;
}

.doc__modal {
  width: 312px;
  margin-top: 24px;
  margin-bottom: 24px;
  height: auto;

  &__preview {
    display: flex;
    align-items: center;
    width: 312px;
    height: 409px;

    color: white;
    position: relative;

    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    filter: brightness(0.5);

    &--black {
      filter: none;
      background-color: #dedede;
    }

    &--img {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__icon {
      text-align: center;
      color: white;
      width: 0;
      position: relative;
      top: 50%;
      left: -23%;
    }
  }

  &__inputs {
    width: 100%;
    padding: 1.5em 0em 2em 0em;
    color: #414141;
    position: relative;

    &--close {
      text-align: end;
    }

    .doc__modal__inputs__title {
      display: flex;
      flex-direction: row;

      > div {
        padding: 0 0 15px 15px;
      }

      .doc__modal__inputs__title--name {
        font-weight: bold;
        margin: auto;
        padding: 0;
      }

      &--options {
      }

      &--other {
        position: relative;
        top: -15px;
        padding-left: 55px;
      }
    }

    .doc__modal__inputs__expDate {
      display: flex;
      flex-direction: row;

      &--name {
        font-weight: bold;
        margin: auto;
        width: 43%;
        white-space: nowrap;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      &--input {
        border-radius: 26px;
        height: 2.5em;
        border: 2px solid #414141;
        margin-left: 15px;
        font-size: 13px;
        font-weight: bold;
        padding-left: 15px;
      }
    }

    .doc__modal__inputs__buttons {
      display: flex;
      justify-content: space-between;

      position: relative;
      bottom: 2em;

      > a > button,
      > button {
        margin-bottom: 12px;
      }
    }

    &__displayName {
      font-size: 36px;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 20px;
      word-break: break-all;
    }

    &__margin {
      margin-bottom: 15px;
    }

    .doc__modal__inputs--bold {
      font-weight: bold;
    }
  }

  &__loading {
    display: flex;
    width: 60%;
    justify-content: center;
    align-items: center;
  }

  &__error {
    color: red;
    font-style: italic;
  }
}

.dropzone {
  height: 100%;

  outline: none;
}

.booking-table {
  display: flex;
}
.lockbox {
  text-align: center;
}

.vehicle-page-layout {
  position: fixed;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  margin-left: 32px;
}

.suggestions {
  position: relative;
  box-shadow: 2px 2px 2px 2px #00000029;
  border-top-width: 0;
  background-color: #ffffff;
  list-style: none;
  margin-top: 0;
  margin-left: 32px;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions-edit {
  position: relative;
  top: -143px;
  box-shadow: 2px 2px 2px 2px #00000029;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  margin-left: 32px;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #f2f2f2;
  color: #128dff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
}

.under-text-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

@import "../../../../constants/variables.scss";

@mixin text {
  color: $dark-grey;
  font-size: 16px;
}

.operationSymbol {
  flex-grow: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 0.4em;
  vertical-align: text-top;
  z-index: 10;
}

.operationStatus {
  &Identifier {
    position: relative;
    display: flex;
    padding-right: 24px;
  }

  &Text {
    @include text;
    font-weight: bold;
    line-height: 1;
    &Container {
      line-height: 1;
      display: flex;
      justify-content: space-between;
    }
  }
}

.symbolLine {
  position: absolute;
  left: calc(15px / 2 - 1px);
  width: 1px;
  height: 120px;
  background-color: #dedede;
  z-index: 1;
  &Coverup {
    z-index: 10;
    position: absolute;
    left: calc(15px / 2 - 1px);
    width: 1px;
    background-color: white;
    height: 120px;
  }
}

.container {
  margin: 0 32px 32px 32px;
  display: flex;
}

.textContainer {
  flex-grow: 1;
}

.subtextContainer {
  display: flex;
  flex-direction: column;
  font-size: 62.5%;
  color: $fleet-grey;
}

.timeContainer {
  display: flex;
}

.userImage {
  width: 100%;
}

.userImageContainer {
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px $dark-grey;
}

.userNameLink {
  margin-left: 8px;
  color: #128dff;
}

.userName {
  margin-left: 8px;
}

.userContainer {
  display: flex;
  align-items: flex-start;
}

.comments {
  // overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.textInfo {
  display: flex;
  flex-direction: row;
}

.coreText {
  margin-bottom: 8px;
}

.statusInfo {
  display: flex;
  flex-direction: row;
}

.statusSymbol {
  flex-grow: 0;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-right: 0.3em;
  z-index: 10;
}

.to {
  width: 45px;
  font-weight: bold;
  text-align: right;
}

.circleInfo {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookingFromTo {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.bookingInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dataChangeTitle {
  margin-bottom: 5px;
}

.vehicleFromTo {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.changedTag {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 3px 9px;
  border-radius: 13.5px;
  border: none;
  height: 25px;
  width: fit-content;
  margin: 5px;
  font-size: 14px;
  background-color: rgba(65, 65, 65, 0.25);
}

.seeMore {
  color: #128dff;
  cursor: pointer;
}

.headerLine{
  border: 1px solid #DEDEDE;
}

.actionGroup{
  display: flex;
  align-items: center;
}

.actions{
  display: flex;
  justify-content: flex-end;
}

.marginLeft{
  margin-left: 24px;
}

.smallMarginRight{
  margin-right: 12px;
}

.blue{
  color: #128dff;
}

.changeMargin{
      margin-top: 12px;
}

.table{
  height: 272px;
  overflow-y: scroll;
  padding-top: 12px;
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
}

.check{
  font-size: 125%;
}
.cellData{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 16px;
}

.headerData{
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  padding: 0px 16px;
}
.options {
  width: 150px;
  height: 205px;
  bottom: 10px;
  right: 0;
  position: absolute;
  background-color: #ffffff;
  border: 0.5px solid #dedede;
  border-radius: 15px;
  box-shadow: 2px 2px 2px #00000029;

  &Item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    cursor: pointer;
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  &Container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  &Box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 9px 2px 11px 2px;
    overflow-y: scroll;
  }

  &Nub {
    position: absolute;
    background-color: #ffffff;
    right: 13px;
    bottom: -4px;
    width: 7px;
    height: 7px;
    box-shadow: 2px 2px 2px #00000029;
    border: 0.5px solid #dedede;
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
  }
}

.listContainer {
  position: relative;
}

.selected {
  color: #128dff;
  font-weight: bold;
}

.marginLeft {
  margin-left: 12px;
}

.button {
  background: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  opacity: 1;
  width: 34px;
  height: 34px;
  font-weight: bold;
  padding: 0 0 8px 0;
  border-radius: 50%;
  border: 1px solid #414141;
  color: #414141;
  transition: all 0.25s ease 0s;
  &:focus {
    outline: none;
  }
  &Container {
    display: flex;
    justify-content: center;
    width: 34px;
  }
}

.buttonVehicle {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainerOpen {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #414141;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listOpen {
  background: #414141;
  color: #ffffff;
}

.selected {
  color: #ffffff;
}

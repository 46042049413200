.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.locationContainer {
  position: relative;
}

.locationCounter {
  position: absolute;
}

@import "../../constants/breakpoints.scss";
@import "../../constants/variables.scss";

.report-container {
	margin: 0 0 0 228px;
	padding-right: 32px;
	display: flex;
	flex-direction: column;
	width: calc(100vw - 288px);
	justify-content: space-between;
	height: calc(100vh - 66px);
	overflow-y: auto;
	overflow-x: hidden;
}
@media only screen and (max-width: 900px) {
	.report-container {
		margin: 0 0 0 228px;
		padding-right: 32px;
		display: flex;
		flex-direction: column;
		width: max-content;
		justify-content: space-between;
		min-height: 450px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
	.report-container {
		margin: 0 0 0 228px;
		padding-right: 32px;
		display: flex;
		flex-direction: column;
		width: max-content;
		justify-content: space-between;
		height: max-content;
		overflow-y: auto;
		overflow-x: hidden;
	}
}
.damage-left {
	width: 30%;
}

@media (max-width: 1024px) {
	.report-container {
		flex-direction: column;
	}
	.damage-left {
		width: 100%;
	}
}
.damage-right {
	margin: 1.5em 28px 0 0;
}

.resolve-container {
	display: flex;
	justify-content: flex-end;
	transition-duration: 2s;
}

.damage-buttons-container {
	display: flex;
	align-items: center;
	.view-buttons {
		margin-top: 1em;
		margin-right: 1em;
		font-size: 80%;
		width: 12em;
	}
}

.damage-buttons-container > div {
	margin: 1em 1em 0 0;
}

.margin-space {
	margin-top: 2em;
}

.individual-report {
	margin-bottom: 1em;
	cursor: pointer;
}

.back-min-width {
	min-width: 4.5em;
}

.report-description {
	color: #aaa;
	font-size: 80%;
	margin: 0 0 0 2em;
	max-width: 30vw;
}

.report-location-check {
	margin: 0.9em 0 0 -0.4em;
}

.report-location {
	margin: 0 0 0.3em 0;
}

.report-created {
	margin: 0;
}

.check-container {
	display: flex;
}

.damage-image-container {
	margin-top: 3em;
	position: relative;
	width: max-content;
}

.damage-overview-image {
	/* at 60vw wide, original W/H ratio is 60 : 38.13916 */
	/* this is to maintain image size relative to a single dynamic screen param */
	width: 60vw;
	height: 38.13916vw;
}

.three-percent {
	width: 3%;
}

.damage-image {
	margin-top: 1em;
	max-width: 15em;
	height: auto;
}

.damage-image-div {
	display: block;
	margin-left: auto;
}

.marker {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.marker-large {
	position: absolute;
	width: 40px;
	height: 40px;
}

.marker-small {
	width: 25px;
	height: 25px;
}

.marker:hover {
	opacity: 1;
}

.marker-container {
	position: absolute;
	top: -20px;
	left: -20px;
}

.marker-margin {
	margin: 0.7em;
}

.small-text {
	font-size: 80%;
}

.standard-opacity {
	opacity: 0.85;
}

.light-opacity {
	opacity: 0.6;
}

.opaque {
	opacity: 1;
}

.remove-last-coord {
	cursor: pointer;
}

.none {
	display: none;
}

.damage-scope {
	margin-right: 1.5em;
	cursor: pointer;
}

.selected {
	border-bottom: 5px solid $blue;
}

.damage-heading {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.damage-tabs {
	display: flex;
}

.other-location {
	display: flex;
	margin-bottom: 1em;
	align-items: center;
}

.other-location > p {
	margin-right: 1em;
}

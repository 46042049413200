$black: #424242;
$blue: #2190fb;
$blue-hover: #51a0fb;
$shadow: 0 3px 6px rgba(92, 46, 46, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$border-radius: 3px;
$grey: #dedede;
$dark-grey: #414141;
$light-grey: #f1f2f2;
$medium-grey: #a2a2a2;
$fleet-grey: #999999;

$navbar-height: 80px;
$navbar-height-mobile: 50px;

$header-height: 100px;
$header-height-mobile: 36px;

$footer-height: 100px;
$footer-height-mobile: 52px;
$footer-height-tall: 86px;

$bold: 600;
$regular: 400;

$content-width: 1100px;

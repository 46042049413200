.sf-wrapper {
	display: flex;
	justify-content: space-evenly;
	height: auto;
}

.sf-list {
	align-items: center;
	text-align: center;
	margin: 1px 0 1px 0;
}
.sf-list-item {
	display: flex;
	font-size: 14px;
	padding-bottom: 11px;
	list-style: none;
}
.sf-nested-item {
	display: flex;
	font-size: 12px;
	padding-bottom: 11px;
	list-style: none;
	padding-left: 19px;
}

.sf-item-name {
	display: flow-root;

	padding-left: 5px;
}

/*.sf-list-header{
	width: 70%;
	display: inline-block;
	text-align: left;
	font-weight: 800;
	font-size: 24px;
	color: #414141;
	padding-bottom: 0.7em;
	border-bottom: 1px solid #DEDEDE;
}*/
/*.sf-list-footer{
	font-size: 10px;
	text-align: center;
}*/
/*.sf-ul-box{
	text-align: center;
}*/
.sf-ul {
	width: 70%;
	display: inline-block;
	text-align: left;
	padding: 0;
	list-style: none;
}
.sf-inner-ul {
	padding-left: 0;
}
.selected-list {
	font-weight: 700;
	color: #128dff;
}
/*.sf-apply-box{
	text-align: center;
	margin-top: 13px;
}
.sf-apply{
	font-size: 16px;
	font-weight: 800;
	width: 132px;
	height: 39px;
}*/

@media (max-width: 1024px) {
	.sf-list {
		align-items: center;
		text-align: left;
		margin: 1px 0px 1px 0px;
	}
	.sf-wrapper {
		display: flex;
		justify-content: space-around;
		height: auto;
	}
	.sf-list-item {
		display: flex;
		font-size: 12px;
		padding-bottom: 11px;
		list-style: none;
		width: min-content;
	}
	.sf-nested-item {
		display: flex;
		font-size: 11px;
		padding-bottom: 11px;
		list-style: none;
		padding-left: 12px;
		width: min-content;
	}
}

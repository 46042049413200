.note {
  width: auto;
  min-width: -webkit-fill-available;
}

.notesPageContainer {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notesHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

svg.cross.icon {
  height: 25px;
  width: 25px;
}

.title {
  font-size: 36px;
}

.notesTitle {
  color: #414141;
  font-size: 28px;
  font-weight: bold;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.damageNotesContainer {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0;
}

.deleteContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 155px;
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
}

.message {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
}

.actionButtons {
  position: relative;
  top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pinnedDamageNotesContainer {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  margin: 0;
}

.editNotesContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 314px;
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
}

.reminderContainer {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 260px;
  background-color: #f2f2f2;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
}

.dateInput {
  color: grey;
  display: flex;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  padding: 0 12px 0 12px;
  margin: 0 0 0 12px;
  width: 180px;
  height: 34px;
}

.addNoteHeader {
  border-bottom: 1px solid #dedede;
}

.addNoteTitle {
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
}

.textboxInput {
  padding: 8px 12px 8px 12px;
  resize: none;
  width: 100%;
  height: 112px;
  border: 1px solid #dedede;
  border-radius: 3px;
  font-size: 16px;
  margin-bottom: 16px;
}

.underTextContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.noteButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 32px 12px;
}

.remindOnText {
  margin-right: 8px;
  opacity: 0.5;
}

.reminderBox {
  width: fit-content;
  height: 34px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;

  border: 1px solid #a7a7a7;
  border-radius: 17px;
  padding: 0 0 0 8px;
}

.reminderDates {
  padding-right: 6px;
  opacity: 0.5;
}

.iconButtons {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  margin-left: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pinDeleteNote {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pinNote {
  font-size: 16px;
  cursor: pointer;
  color: #414141;
}

.addNote {
  justify-content: flex-end;
  font-size: 17px;
}
.editNote {
  margin-top: 24px;
  justify-content: flex-end;
  font-size: 17px;
}

.addButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cancelNote {
  margin-top: 24px;
  justify-content: flex-start;
  font-size: 17px;
}

.editNoteButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.notesBox {
  min-height: 304px;
}

.unavailableInfo {
  min-height: 304px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-size: 16px;
}

.addNoteTitleLine {
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 32px;
  border-bottom: 1px solid #dedede;
}

.editTextboxInput {
  padding: 0.6em;
  resize: none;
  width: 536px;
  height: 112px;
  border: 1px solid #dedede;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 32px;
}

.deleteNote {
  font-size: 16px;
  cursor: pointer;
}

.noteDetailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 28px;
}
.noteDetails {
  word-wrap: break-word;
  margin: 12px 0 12px 0;
  color: #414141;
  font-size: 16px;
  max-width: 490px;
}

/*.docContainer {
	display: flex;
	flex-direction: column;
	width: 800px;
	height: 400px;
	background-color: #f2f2f2;
}


.docHeader {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 32px 0 2em 0;
}

.docTitle {
	color: #414141;
	font-size: 31px;
	font-weight: bold;
}*/

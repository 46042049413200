.inspection-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  min-width: 864px;
  max-width: 900px;
  padding: 32px;
  border-radius: 16px;
  border: none;
  > h2 {
    margin-top: 0;
    font-weight: 700;
  }
}

.car-change {
  min-width: 700px;
}

.exterior {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.vehicle-safety {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vehicle-cleanliness {
  position: relative;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inspection-button {
  bottom: 32px;
  right: 32px;
}
.close-button {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.exterior-img {
  object-fit: cover;
  border-radius: 20px;
  width: 80px;
  height: 80px;
  background-color: #dedede;
  border: none;
  cursor: pointer;
}

.img-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.img-container-safe {
  // width: 70%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.safety-condition-good {
  width: 70%;
  color: #27ae60;
}

.safety-condition-bad {
  color: red;
  display: flex;
  flex-direction: row;
}

.bad-safety {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.driver-action {
  color: #414141;
  opacity: 0.5;
  margin-left: 15px;
}

.rating-clean {
  flex-direction: row;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.clean-comment {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.driver-words {
  margin-top: 15px;
  word-wrap: break-word;
}

.vehicle-image-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  margin-bottom: 24px;
}

.previous-container {
  display: flex;
  flex-direction: column;
}

.new-container {
  display: flex;
  flex-direction: column;
}

.car-change-img {
  width: 270px;
  height: 88px;
  margin: 16px 0;
}

.change-arrow {
  align-self: center;
}

.car-plate {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.close-img {
  border-color: red;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 580px;
  top: -10px;
  font-size: 20px;
  cursor: pointer;
}

.full-image-container {
  display: flex;
  flex-direction: row;
  position: absolute;
}

.next-button {
  color: #dedede;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  left: 549px;
  top: 300px;
  font-size: 32px;
  cursor: pointer;
}

.prev-button {
  color: #dedede;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 300px;
  font-size: 32px;
  cursor: pointer;
}

.next-button :hover {
  color: #128dff;
}

.prev-button :hover {
  color: #128dff;
}

.full-image-display {
  width: 600px;
  height: 700px;
}

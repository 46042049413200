#new-avc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-item {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 80%;
}

.field-container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.lock-buttons-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-buttons-div {
  display: flex;
}

.table-div {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fleet-container {
  display: flex;
  flex-direction: column;
  margin-top: 9em;
}

.vehicle-data-center {
  text-align: center;
}

.inspection-title {
  padding-bottom: 1em;
}

@media (max-width: 1024px) {
  .inspection-title {
    padding-top: 1em;
  }
}

.table-6 {
  width: 15%;
}

.table-5 {
  width: 38%;
}

.table-4 {
  width: 20%;
}

.table-3 {
  width: 10%;
}

.table-2 {
  width: 10%;
}

.table-1 {
  width: 5%;
}

.vehicle-pic {
  height: 35px;
  width: auto;
  transform: translateX(-0.8em);
}

.flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.first-upcoming {
  margin-left: 32px;
}

.first-history {
  margin-left: 32px;
}

.first-inspection {
}

.first-fleet {
  /*margin-left: -1.8vw;*/
  /*margin: 0 0.5em 0 -2.3em;*/
}

/* @media (max-width: 1024px) {
  .first-fleet {
    margin: 0 0.7em 0 -0.7em;
  }
} */

@media (max-width: 1024px) {
  .inspection {
    font-size: 70% !important;
  }
}

@media (max-width: 1024px) {
  .first-inspection {
  }
}

/*.last-insp-date {
  margin-right: 2em;
}*/

/*.last-insp {
  margin-right: 4em;
}*/

.status-red {
  background-color: #fb7272;
}

.status-yellow {
  background-color: #f5a522;
}

.status-green {
  background-color: #63ca9c;
}

.status-grey {
  background-color: #999;
}

.status-icon {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.status-div {
  display: inline-flex;
  justify-content: left;
  width: 65%;
  margin: 1em 17.5% 1em 17.5%;
}
.status-box {
  margin-left: 0.4em;
}
.service-status {
  margin: 0;
}

.service-milage {
  margin-top: 10px;
}

.vehicle-row {
  padding: 0.5em 0 0.5em 0;
  cursor: pointer;
  border-bottom: 1px solid #d3d3d3;
}

.service-row {
  padding: 0.5em 32px 0.5em 0;
  cursor: pointer;
  border-bottom: 1px solid #d3d3d3;
}

@media (max-width: 1024px) {
  .service-row {
    padding: 0.5em 0em 0.5em 0;
  }
}

.vehicle-row:hover {
  background-color: #f8e9e9;
  transition: 0.3s;
}

.fleet-header {
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  padding: 0.3em 0 0.3em 0;
}

.link {
  text-decoration: none;
  color: black;
}

.link:visited {
  color: black;
}

.vehicle-data {
  font-size: 90%;
  font-weight: 400;
  text-align: center;
}

.fleet-grey {
  color: #999999e8;
  font-size: 10px;
  text-align: center;
}

.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.sortable {
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.sortable-icon {
  position: absolute;
  margin-left: 0.6em;
}

.select-filter {
  align-items: top;
}
.fleet-table-header {
  position: sticky;
  top: 97px;
  z-index: 5;
  background-color: #ffffff;
  margin-bottom: 12px;
  margin-right: 32px;
}

.search-options {
  display: inline-block;
  width: 70%;
  text-align: center;
  margin-top: 0.25em;
  margin-left: 15%;
  margin-right: 15%;
  font-size: 10px;
  color: #414141;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.search-options:hover {
  white-space: pre-line;
}

.select-vehicles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-actions-container {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: calc(100vh - 182px);
  right: 34px;
}

.group-actions {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 232px;
  height: 34px;
  background-color: #ffffff;
  border: 1px solid #414141;
  box-shadow: 0px 4px 6px #00000052;
  border-radius: 25px;
  font-size: 17px;
  cursor: pointer;
}
.change-hub-container {
  margin: 16px 32px 16px 32px;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.city-title {
  font-size: 18px;
  font-weight: bold;
}
.select-hub {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #414141;
}
.lot-name {
  font-size: 16px;
  margin-left: 12px;
}

.hub-option {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 16px 0px 16px 0px;
  cursor: pointer;
}

.change-hub-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  bottom: 0;
}

@media (max-width: 1024px) {
  .search-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 0.25em;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 10px;
    color: #414141;
    white-space: normal;
  }
}

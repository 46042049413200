.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  min-height: 100vh;
  min-width: 1044px;
  color: #414141;
}

.containerPopup {
  display: flex;
  flex-direction: column;
  color: #414141;
}

.containerSmall {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // padding: 20px 0 0 32px;
  min-height: 100vh;
  color: #414141;
  min-width: 900px;
  // width: 1200px;
}

.link {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
  // margin-right: 32px;
  // max-width: 1575px;
}

.table {
  width: max-content;
  min-width: calc(100%);
  padding: 0 32px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.tableContainerPopup {
  overflow-x: auto;
  width: 100%;
  height: 455px;
  overflow: auto;
}

.tableCell {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 120px;
}

.tableCellFlex {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: fit-content;
}

.notClick {
  pointer-events: none;
  cursor: default;
}

* {
	box-sizing: border-box;
}

.flex-col {
	display: flex;
	justify-content: center;
}

.flex-row {
	display: flex;
	justify-content: space-around;
}

.user-nav {
	/*justify-content: space-around;
	align-items: center;*/
	display: block;
	padding: 0 0.2em 19px 0.2em;
	text-align: center;
}

.logo {
	/*margin-left: 3em;*/
	width: 60px;
}

.user-image {
	width: 35px;
	height: auto;
	border-radius: 50%;
	margin-bottom: 35px;
}

.nav-banner {
	width: 60px;
	height: 100%;
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	/*padding: 0.2em;*/
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	display: table;
	/*align-items: center;
	justify-content: space-between;*/
	z-index: 20000000;
}

.links {
	text-decoration: none;
}

.navbar-link {
	color: #ffffff;
	font-weight: 400;
	cursor: pointer;
	text-align: center;
	/*margin: 0 0.8em;*/
	width: 60px;
}

.navbar-link:hover {
	opacity: 0.9;
}

.navbar-link-container {
	height: 100vh;
	/*display: flex;
	justify-content: flex-end;
	flex-direction: row;*/
}

.nav-footer {
	position: absolute;
	bottom: 15px;
}

@media (max-width: 1024px) {
	.navbar-link-container {
		height: 100vh;
	}
}

.nav-path {
	display: flex;
}

.site-body {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.footer-container {
	z-index: 10000;
	position: relative;
	bottom: 0;
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	padding-left: 1.5em;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.copyright-text {
	font-size: 80%;
}

.container {
	height: auto;
	min-height: 100vh;
	background-color: #f2f2f2;
	width: calc(100% - 60px);
	position: absolute;
	left: 60px;
	right: 0px;
}

/*@media only screen and (max-width: 900px) and (orientation: portrait) {
	.container {
		transform: rotate(90deg);
	}
}*/

.dropdown {
	position: absolute;
	background-color: white;
	border: 1px solid #e0e0e0;
	border-radius: 3px;
	transition: 0.2s;
	width: 8em;
	left: 60px;
	bottom: 44px;
	cursor: pointer;
	-webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
}

.dropdown:hover {
	background-color: #f8f8f8;
}

.visible {
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.white {
	color: #ffffff;
}

.invisible {
	display: none;
}

.icon-container {
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
}

.icon-image {
	width: 30px;
	height: 30px;
}

@media (max-width: 1024px) {
	.container {
		height: auto;
		min-height: 100vh;
		width: 964px;
		position: absolute;
		left: 60px;
		right: 0px;
	}
}

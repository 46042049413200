.spotLoading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parkingSpotPage {
  padding: 20px 64px 20px 32px;
  background-color: #f2f2f2;
  color: #414141;
  min-width: 768px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin: 16px 0;
}

.editButton {
  width: 100px;
  height: 36px;
  color: #414141;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  cursor: pointer;
  align-items: center;
}

.editIcon {
  margin-right: 3px;
}

.pageContentContainer {
  width: 100%;
  background-color: #ffffff;
  padding: 20px 32px;
  min-height: 800px;
}

.basicField {
  display: flex;
  flex-direction: column;
}

.statusTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding: 2px 12px;
  border-radius: 14px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 13px;
  font-weight: 700;
  width: fit-content;
}

.notApplicable {
  opacity: 0.5;
}

.byLink {
  color: #128dff;
  text-decoration: none;
}

.actionContainer {
  width: 500px;
  max-width: 500px;
  padding: 32px;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.notesCancelButton {
  position: absolute;
  left: 0;
  bottom: 0;
}

.notesSaveButton {
  position: absolute;
  left: 286px;
  bottom: 0;
}

.dateFilterContainer {
  display: flex;
  flex-direction: row;
  margin: 36px 0;
}

.timelineTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.datePickerBox {
  display: flex;
  flex-direction: column;
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 280px;
  border: 1px solid #414141;
  border-radius: 12px;
  padding: 0 8px 0 16px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
}

.pickDateCalendar {
  position: relative;
  right: 36px;
}

.basicFieldSubTitle {
  font-size: 12px;
  margin-bottom: 6px;
}

.parkingTag {
  display: flex;
  height: 24px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
}

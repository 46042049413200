.google-map {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 100%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.history-loading-div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	height: 100%;
}

.history-loading-frame {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #808080b3;
	z-index: 10;
}

.loading-icon {
	color: white;
}

@media (max-width: 1024px) {
	.google-map {
		height: 85vh;
	}
}

.start-marker {
	transform: translateY(-0.5em);
	background: white;
}

.parking-icon {
	cursor: pointer;
	/* translate to have bottom of point @ coordinate, rather than top left */
}

.tuner-container {
	z-index: 100000;
	width: 100%;

	background: white;

	margin: 0.5em auto 1em auto;
}

.parking-lot {
	position: absolute;
	top: 24px;
	left: 24px;
	.parking-lot-button {
		width: 150px;
	}
}

.centering {
	position: absolute;
	top: 24px;
	left: 24px;
	.centering-button {
		width: 150px;
	}
}

.bottom-box {
	position: absolute;
	bottom: 32px;
	left: 32px;
}

.targeted-time {
	background: white;
	padding: 0 8px 0 15px;
	color: #414141;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.5;
	text-align: center;
	font-size: 1.2em;
	padding: 5px;
}

.toggle-box label span {
	font-weight: 400;
	font-size: 1.1em;
	width: 50px;
}

.toggle-box {
	.map-toggle {
		border: none;
		box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
		height: 50px;
		width: 150px;
	}
}
.parking-toggle-label {
	font-weight: bold;
}

.unchecked-toggle svg {
	color: #999;
}

.checked-toggle svg {
	color: #999;
}

.banner-container {
	text-align: center;
	padding: 0 1em 0 1em;
	height: 3em;
	margin-bottom: 0.3em;
	width: max-content;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transform: translateY(-8em);
	display: none;
	z-index: 4;
}

.banner-text {
	font-family: "Montserrat", sans-serif;
	display: inline-block;
}

.parking-icon:hover + .banner-container {
	display: block;
}

.driver-details-card {
	left: 2em;
	top: 28em;
	background-color: #fff;
	padding: 1em;
}

.card-container {
	transform: translateY(-17em);
	display: flex;
	flex-direction: column;
	padding: 0;
	align-items: center;
	padding: 0.5em 0.5em 0.5em 1.9em;
	background-color: #fff;
	position: absolute;
	width: 22em;
	border-radius: 4px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.search-bar {
	left: 2em;
	min-width: 22em;
	min-height: 1em;
	top: 23em;
	background-color: #fff;
}

.card-text {
	margin-bottom: 0;
}

.test-marker {
	width: 5px;
	height: 5px;
	background: red;
	z-index: 1000000000000000;
}

.steering-icon {
	width: 30px;
}

.grey-steering {
	color: #b0b0b0;
}

@import "../../constants/breakpoints.scss";
@import "../../constants/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 28em;
  position: relative;
}

.title {
  font-size: 24px;
  font-weight: $bold;
}

.loadingBar {
  height: 30px;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.input {
  &__container {
    display: flex;
  }

  &__date {
  }
}

.buttonsGroup {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 95%;
  justify-content: space-around;
}

.errors {
  font-style: italic;
  color: red;
  font-size: 12px;
}

.breakWord {
  word-wrap: break-word;
}

.center {
  text-align: center;
}

.showContent {
  width: 985px;
  height: 252px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px 42px;
  display: flex;
  flex-direction: column;
}

.titles {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 700;
}

.titleTime {
  padding-left: 20px;
  width: 193px;
  font-weight: 700;
}

.titleMsg {
  padding-left: 20px;
  font-weight: 700;
}

.titleStartTime {
  padding-right: 157px;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.handleHover {
  pointer-events: none;
}

.recordBox {
  width: 903px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px;
  // :hover {
  //   background-color: rgba(18, 141, 255, 0.1);
  // }
}

.curDTCCloseButton {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.rowDTCInfo {
  padding: 1px 6px 0 7px;
  height: 57px;
  // border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
}

.titleTimeInner {
  width: 172px;
  pointer-events: none;
}

.titleMsgInner {
  display: block;
  overflow-wrap: break-word;
  width: 646px;
  pointer-events: none;
  padding-left: 20px;
}

.infoCircle {
  width: 537px;
  height: 399px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 38px 25px 32px 25px;
  display: flex;
  flex-direction: column;
}

.recordBoxSmall {
  width: 486px;
  height: 240px;
  background-color: #ffffff;
  overflow-y: auto;
  border-radius: 5px;
  padding: 12px;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.titlesSmall {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.rowInfo {
  padding: 8px 15px 6px 15px;
  height: 34px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
}

.rowInfoCircle {
  padding: 8px 2px 6px 2px;
  height: 34px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

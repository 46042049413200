.management-search-bar {
	left: 2em;
	min-width: 22em;
	min-height: 1em;
	top: 23em;
	background-color: #ffffff;
}

svg.management-search-icon {
	font-size: 1.5em;
}

input.search-input {
	opacity: 0.5;
	color: #414141;
}

.filter-title {
	margin-bottom: 8px;
	margin-right: 0.3em;
}

.log-search-bar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	height: 34px;
	border: 0.5px solid #dedede;
	border-radius: 25px;
}

.log-search-input {
	width: 190px;
	height: 34px;
	padding-left: 8px;
}

.status-log-header {
	display: flex;
	justify-content: left;
	align-items: center;
}

.custom-time-select {
	width: 180px;
	height: 34px;
	border: 0.5px solid #dedede;
}

body {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Helvetica Neue", "Helvetica", "sans-serif";
  font-weight: 400;

}

.actionBar {
  display: flex;
  justify-content: space-between;
  margin: 16px 32px 26px;
}

.leftGroup,
.rightGroup {
  display: flex;
}

.toggleTasksButtonContainer {
  display: flex;
  flex-direction: row;
  margin-right: 32px;
  align-items: center;
}

.taskButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionsBody{
  display: flex;
  justify-content: space-around;
}

.column{
  font-weight: normal;
  cursor: default;
  color: inherit;
  font-size: inherit;
  justify-content: space-around;
  width: 100%;
}

.columnHeader{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 17px;
  font-weight: bold;
  width: 169px;
  margin-bottom: 10px;
  &Text{
    font-size: 17px;
    font-weight: bold;
  }
}

.columnBody{
  overflow-y: auto;
  height: 370px;
  overflow-y: auto;
  border: 1px solid #dedede;
}

.presetOption{
  display: flex;
  align-items: center;
  padding: 10px;
  height: 38px;
}

.name{
  display: flex;
  font-size: 17px;
  margin: 0 0 0 12px;
  text-align: left;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
}

.selectedContainer{
  background-color: rgb(242, 242, 242);
  font-weight: bold;
}

.selectedButton{
  background-color: rgb(65, 65, 65);
}

.columnOption{
  display: flex;
  align-items: center;
  padding: 10px;
  height: 38px;
}

.marginRight{
  margin-right: 16px;
}

.deleteButton{
  color: #FF0000;
  margin-left: 12px;
  width: 11px;
  height: 13px;
}

.saveNewPreset{
  text-align: center;
  color: #128dff;
  margin: 14px 0;
}

.editButton{
  font-size: 16px;
  color: #128dff;
}
.login-button {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  margin-top: 15px;
  width: 15rem;
  height: 36px;
  text-transform: uppercase;
  font-weight: 300;
  border-radius: 4px;
  background-color: white;
  color: #333;
  font-size: 80%;
  padding: 0.6em;
  outline: none;
  transition-duration: 0.5s; 
  cursor: pointer;
}
  .login-button:hover {
    background-color: #BABABA;
    border: none; }

.sign-in-field {
  display: flex;
  flex-direction: column;
}

.login-error {
  color: red;
  font-weight: 50;
  font-size: 75%;
}

@-webkit-keyframes autofill {
  to {
    color: #9e9e9e;
    background: transparent;
  }
}

@keyframes autofill {
  to {
    color: #9e9e9e;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
@import "../../../constants/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 1044px;
}

.filters{
  display: flex;
  margin: 32px;
}

.search{
  &Bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 34px;
    border: 0.5px solid #dedede;
    border-radius: 25px;
    padding: 0 16px;
  }

  &Icon{
    font-size: 14px;
    opacity: 0.5;
    color: $dark-grey;
  }

  &Input{
    font-size: 16px;
    color: $dark-grey;
    line-height: 1;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 16px 8px;
    min-width: 0;
    opacity: 0.5;
    &:focus{
      outline: 0;
    }
  }
}

.date{
  &Icon{
    font-size: 16px;
    color: $dark-grey;
    opacity: 0.5;
  }
  &Clear{
    color: $blue;
  }

  &Selected{
    opacity: 1;
    font-size: 16px;
    color: $dark-grey;
  }
}

.table{
  &Header{
    &Text{
      font-weight: bold;
      text-align: left;
    }
  }
}

.orDivider{
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errors {
  margin: 12px;
}

.confirmBooking {
  &__title {
    font-size: 36px;
    color: red;
    width: 100%;
    text-align: center;
  }
  &__text {
    font-size: 24px;
    margin-bottom: 24px;
    width: 100%;
    text-align: center;
  }
}

.triangle {
  position: absolute;
  top: 258px;
  left: 632px;
  width: 0;
  height: 0;
  border-right: 0.5em solid transparent;
  border-top: 1px solid #f2f2f2;
  border-left: 0.5em solid transparent;
  z-index: 1;
  box-shadow: 0px 0px 2px 2px #00000029;
}

.service {
  &Status {
    font-size: 16px;
    color: #414141;
    margin-left: 6px;
    margin-top: 3px;
  }
  &Options {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
    cursor: pointer;
  }

  &OptionsBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 140px;
    height: 133.49px;
    padding: 7px 0 7px 0px;
    position: relative;
    top: -136px;
    left: -100px;
    background-color: #ffffff;
    border: 0.5px solid #dedede;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #00000029;
  }
}

.black {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #414141;
  &__border {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #414141;
    background-color: none;
  }
}

.red {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #ff0000;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.deleteButton {
  margin-top: 24px;
}

.searchContainer {
	width: 100%;
	display: flex;
	font-size: 16px;
	color: #414141;
	background-color: #ffffff;
	border-radius: 25px;
	padding: 7px 10px 7px 10px;
}

.icon {
	font-size: 90%;

	margin-right: 10px;
}

.iconContainer {
	display: flex;
	align-items: center;
}

.drawerContainer {
	background-color: #f2f2f2;
	width: 444px;
}

.carStatus {
	text-align: -webkit-center;
	width: 120px;
}

.vehicleStatus {
	font-size: 11px;
	opacity: 0.5;
	color: #414141;
	&__tier {
		font-size: 11px;
		color: #414141;
		opacity: 0.7;
	}
}

.locationLink {
	color: #128dff;
	text-decoration: none;
	font-size: 15px;
}

.editBox{
  width: 100px;
  height: 22px;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  &:focus{
    border-color: #128dff;
    outline: none;
  }
}

.markEdited{
  border: 1px solid #414141
}
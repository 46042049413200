@import "../../../constants/breakpoints.scss";
@import "../../../constants/variables.scss";

.drawer-container {
  transition: width 0.3s ease-in;
  white-space: "nowrap";
  position: relative;
  display: flex;
  align-content: center;
  background-color: #f2f2f2;
}

.button-container {
  display: flex;

  justify-content: flex-end;
  padding-right: 0.25em;
}

.back-color {
  background-color: #f2f2f2;
}

#vehicle-summary {
  display: flex;
  flex-direction: row;
}

.list-header {
  display: flex;
  align-content: center;
  align-items: center;
  width: 444px;
  height: 74px;
  padding: 0px 0px 12px 0px;
  background-color: #f2f2f2;
}

.list-header-search {
  position: absolute;
  top: 110px;
  z-index: 5;
  display: flex;
  align-content: center;
  align-items: center;
  width: 444px;
  height: 74px;
  padding: 0px 0px 12px 0px;
  background-color: #f2f2f2;
}

.list-header-title {
  position: absolute;
  top: 36px;
  display: flex;
  align-content: center;
  align-items: center;
  width: 444px;
  height: 74px;
  background-color: #f2f2f2;
}

.select-filter-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 50%;
}

#vehicle-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vehicle-type-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vehicle-details-map-tab {
  display: flex;
  justify-content: right;
  width: 380px;
  background-color: #f2f2f2;
}

.vehicle-image {
  height: 80px;
  width: auto;
}

.vehicle-pic-drawer {
  height: 40px;
  width: auto;
}

.vehicle-section-title {
  font-weight: bold;
  font-size: 1.3em;
}

.vehicle-information-map-container {
  display: flex;
  flex-direction: column;
}

.vehicle-header-container {
  margin: 1em 0 2em 0;
}

.vehicle-info-map-type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.2em 0 0.2em 0;
  font-style: normal;
  font-size: 110%;
}

.vehicle-info-type-data {
  text-align: right;
}

.vehicle-pic-drawer-cell {
  padding-right: 2em;
}

.vehicle-details-container {
  display: flex;
  flex-direction: column;
  margin: 0em 32px 0 32px;
  background-color: #ffffff;
  padding: 32px;
}

.vehicle-tabs-container {
  display: flex;
  flex-direction: column;
  margin: 0em 32px 0 32px;
  background-color: #ffffff;
  padding: 12px;
}
.vehicle-col {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.full-width {
  width: 100%;
  margin-bottom: 1em;
}

.vehicle-container {
  display: flex;
  justify-content: space-between;
}

.header {
  align-items: center;
}

.column {
  width: 47%;
  display: flex;
  flex-direction: column;
}

.display-photo {
  width: 305px;
  height: auto;
  margin-top: 12px;
}

.neg-margin {
  margin-right: -2em;
}

.end {
  display: flex;
  justify-content: flex-end;
  text-align: end;
}

.blue {
  color: $blue;
}

.show-more {
  cursor: pointer;
}

.small {
  font-size: 12px;
}

.details {
  font-weight: 700;
}

.tight-margin {
  margin: 0.4em 0 0.4em 0;
}

.top {
  margin-top: 2em;
}

.large {
  font-size: 28px;
  font-weight: bold;
}

.text-input {
  resize: none;
  height: 4.5em;
  border-radius: 3px;
  border-color: #6666;
  font-size: 80%;
}

.vehicle-map-information {
  text-align: left;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 12px;
}

.small-info {
  font-size: 12px;
  color: #414141;
  margin: 0;
  text-align: left;
}

.large-info {
  font-size: 19px;
  color: #414141;
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.odometer-map-icons {
  font-size: 15px;
  font-weight: bold;
  margin: 0 10px 0 0px;
}

.fuel-map-icons {
  font-size: 17px;
  font-weight: bold;
  margin: 0 10px 0 0px;
}
.fuel-map-bar {
  width: 67px;
  height: 8px;
  border: 1px solid #414141;
  border-radius: 8px;
  margin-right: 10px;
}
.fuel-map-fill {
  background-color: #414141;
  border-radius: 8px;
  height: 100%;
}
.fuel-map-container {
  display: flex;
  margin: 8px 0 8px 0;
  align-items: center;
}

.details-button-map-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.tab-options-map {
  display: flex;
  cursor: pointer;
  width: 190px;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  opacity: 0.5;
  color: #414141;
}

.search-options-map {
  font-size: 10px;
  text-align: left;
  margin-top: 0.25em;
  width: 60px;
  color: #414141;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.map-add-note {
  cursor: pointer;
  text-align: right;
  font-size: 17px;
  color: #128dff;
  margin: 12px;
}

.search-options-map:hover {
  white-space: pre-line;
}

.map-nav-header {
  width: inherit;
  padding-left: 32px;
  background-color: #f2f2f2;
}

@media (max-width: 1024px) {
  .search-options-map {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    text-align: left;
    margin-top: 0.25em;
    font-size: 10px;
    color: #414141;
    white-space: normal;
  }
}

.getCoordinatesContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mapContainer {
  flex: 1 4;
  transition: margin 0.3s ease-in;
}

.map-open {
  margin-left: 30vw;
}

.map-close {
  margin-left: 8em;
}

.hide {
  display: none;
}

.car-detail-container {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
}

.mode-selection-container {
}
